/* eslint-disable react/no-unescaped-entities */
import React from 'react';
import { Box, Paper, Avatar, Typography, CardMedia } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import FormatQuoteRoundedIcon from '@material-ui/icons/FormatQuoteRounded';
import useWindowDimensions from '../Hooks/useWindowDimensions';
import dustRight from '../../assets/svg/dust-right.svg';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '75%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    marginLeft: theme.spacing(5),
    margin: theme.spacing(17),
    marginBottom: theme.spacing(17) * 2,
    '& > *': {
      margin: theme.spacing(1),
    },
  },
  text: {
    fontFamily: 'Lexend Deca',
    fontWeight: 600,
    fontSize: '1.8em',
    letterSpacing: '0.15px',
    textAlign: 'left',
  },
  smText: {
    fontFamily: 'Lexend Deca',
    fontWeight: 600,
    fontSize: '1em',
    letterSpacing: '0.15px',
    textAlign: 'center',
  },
  smTextLight: {
    fontFamily: 'Lexend Deca',
    fontWeight: 500,
    fontSize: '1em',
    color: '#000',
    letterSpacing: '0.15px',
    textAlign: 'center',
  },
  occupation: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
  },
  bg1: {
    position: 'relative',
    left: '15%',
    right: '0%',
    bottom: '18.5vh', // 128
    width: '70%', // 446
    height: '97.5vh', // 689
  },
  bg2: {
    position: 'relative',
    left: '15%',
    right: '0%',
    bottom: '0%',
    top: '0%', // 128
    width: '70%', // 446
    height: '79vh', // 689
  },
  dustRight: {
    width: '100%',
    height: '100%',
    backgroundImage: `url(${dustRight})`,
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'right center',
    display: 'flex',
    alignSelf: 'center',
  },
}));

const ReviewItems = ({ name, review, occupation, image, imageStyle }) => {
  const classes = useStyles();
  const { height, width } = useWindowDimensions();

  return (
    <Box display='flex'>
      <Box style={{ width: '45%', height: '90vh' }}>
        <CardMedia
          className={imageStyle === 2 ? classes.bg2 : classes.bg1}
          image={image}
          title=''
        />
      </Box>

      <Box style={{ width: '55%', height: '100%' }} className={classes.dustRight}>
        <div className={classes.root}>
          <FormatQuoteRoundedIcon style={{ color: '#000', fontSize: 55 }} />
          <Typography p={2} variant='body1' align='center' className={classes.text}>
            {review}
          </Typography>
          <div className={classes.occupation}>
            <Typography
              p={2}
              variant='body1'
              align='center'
              className={classes.smText}
            >
              {name}
            </Typography>
            <Typography
              p={2}
              variant='body1'
              align='center'
              className={classes.smTextLight}
            >
              {occupation}
            </Typography>
          </div>
        </div>
      </Box>
    </Box>
  );
};

export default ReviewItems;
