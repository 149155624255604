import React from 'react';
import { Box, CardMedia, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import useWindowDimensions from '../Hooks/useWindowDimensions';
import awash from '../../assets/images/sahay/sahay13.jpeg';

const useStyles = makeStyles((theme) => ({
  root: {
    marginBottom: theme.spacing(5),
    backgroundColor: '#fff9d8',
  },
  text: {
    fontFamily: 'Lexend Deca',
    fontWeight: 600,
    fontSize: '15px',
    letterSpacing: '0.15px',
    textAlign: 'left',
    color: '#6b6b6b',
  },
  header: {
    fontFamily: 'Lexend Deca',
    fontWeight: 'bold',
    fontSize: '38px',
    textAlign: 'left',
    lineHeight: '130%',
    marginBottom: '2rem',
    zIndex: 100,
  },
  media: {
    // height: 0,
    // paddingTop: '70.75%',
    width: '27.5rem',
    height: '27.5rem',
    marginRight: '8rem',
    borderRadius: 10,
  },
}));

const Interoperability = () => {
  const classes = useStyles();
  const { height, width } = useWindowDimensions();

  return (
    <Box
      display='flex'
      justifyContent='center'
      className={classes.root}
      style={{ width, height: height - 100 }}
    >
      <Box
        display='flex'
        justifyContent='center'
        alignItems='center'
        p={5}
        pt={2}
        style={{ width: '50%', height: '100%' }}
      >
        <CardMedia className={classes.media} image={awash} title='' />
      </Box>
      <Box
        display='flex'
        flexDirection='column'
        justifyContent='center'
        style={{
          width: '50%',
          paddingRight: 10,
          height: '100%',
        }}
      >
        <Typography className={classes.header}>Interoperability</Typography>
        <Typography
          p={2}
          variant='body1'
          align='center'
          className={classes.text}
          style={{ marginBottom: '2rem' }}
        >
          Sahay Pay has the ability to connect to different systems hence attracting
          a lot of attention among digital finance experts. Interoperable payment
          systems have the potential to make it easier for people to send payments to
          anyone and receive payments from anyone quickly and cheaply. Financial
          service providers welcome the new business opportunities that would emerge
          from this higher volume of transactions, and policy makers see
          interoperability as a means to bring more poor people into the financial
          system, thus fostering financial inclusion.
        </Typography>
        <Typography p={2} variant='body1' align='center' className={classes.text}>
          Currently Sahay Pay is connected to Awash Bank and Rays Microfinance Core
          Banking Systems allowing customers to transfer funds between the wallet and
          their respective accounts. Sahay Pay is ready to partner with more
          financial institutions to foster financial inclusion as one of its primary
          pillars.
        </Typography>
      </Box>
    </Box>
  );
};

export default Interoperability;
