import React from 'react';
import { Box } from '@material-ui/core';
import Description from '../Description/Description';
import Overview from '../Overview/Overview';
import useWindowDimensions from '../Hooks/useWindowDimensions';

const DescriptionSection1 = () => {
  const { height, width } = useWindowDimensions();

  return (
    <Box
      style={{
        display: 'flex',
        flexDirection: 'row',
        width: '100vw',
        height: '100vh',
        backgroundColor: '#9c80f7',
      }}
    >
      <Box
        style={{
          width: '40vw',
          height: height - 100,
          display: 'flex',
        }}
      >
        <Description
          isSection1
          text='Transact safely, affordably, and instantly between friends, family, and business. Trusted by over 600k happy users across Ethiopia.'
        />
      </Box>
      <Box
        style={{
          width: '60vw',
          height: height - 100,
          display: 'flex',
        }}
      >
        <Overview />
      </Box>
    </Box>
  );
};

export default DescriptionSection1;
