import React, { useState } from 'react';
import {
  Box,
  Paper,
  Typography,
  Card,
  CardActionArea,
  CardActions,
  CardContent,
  CardMedia,
  Button,
  CardHeader,
  Tooltip,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import useWindowDimensions from '../Hooks/useWindowDimensions';
import send from '../../assets/images/3d-icons/send-money-3D.png';
import buyGoods from '../../assets/images/3d-icons/buy-goods-3d.png';
import withdraw from '../../assets/images/3d-icons/withdraw-3d.png';
import bills from '../../assets/images/3d-icons/bills-3d.png';
import airtime from '../../assets/images/3d-icons/wallet-3d.png';
import banking from '../../assets/images/3d-icons/Core-banking-3d.png';
import support from '../../assets/images/3d-icons/chat-3d.png';
import loan from '../../assets/images/3d-icons/Digital-micro-loan-3d.png';
import hagbad from '../../assets/images/3d-icons/hagbad.png';
import bulk from '../../assets/images/3d-icons/bulk-payment.png';

const useStyles = makeStyles((theme) => ({
  root: {
    margin: theme.spacing(2),
    width: '25vw',
    height: '50vh',
    borderRadius: '15px',
    backgroundPosition: 'center center',
    backgroundSize: '50%',
    backgroundRepeat: 'no-repeat',
  },
  text: {
    fontFamily: 'Lexend Deca',
    fontWeight: 500,
    color: '#FFFFFF',
    fontSize: '15px',
    textShadow: '0.05em 0 black, 0 0.05em black, -0.05em 0 black, 0 -0.05em black',
  },
  title: {
    fontFamily: 'Lexend Deca',
    fontWeight: 600,
    color: '#fff',
    letterSpacing: '0.15px',
  },
  content: {
    width: '90%',
    height: '45%',
  },
  button: {
    width: '85%',
    fontFamily: 'Lexend Deca',
    fontWeight: 500,
    color: '#000',
    letterSpacing: '0.15px',
    backgroundColor: '#FFFFFF',
  },
  buttonContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
}));

const FeatureItem = ({ title, body, assetProp }) => {
  const { height, width } = useWindowDimensions();
  const [hover, setHover] = useState(false);
  const classes = useStyles();

  const assets = {
    hagbad: [hagbad, 'rgb(237, 91, 123)'],
    send: [send, 'rgb(128, 92, 245)'],
    buyGoods: [buyGoods, 'rgb(128, 92, 245)'],
    withdraw: [withdraw, 'rgb(0, 125, 251)'],
    bulk: [bulk, 'rgb(237, 91, 123)'],
    bills: [bills, 'rgb(0, 125, 251)'],
    airtime: [airtime, 'rgb(0, 171, 250)'],
    banking: [banking, 'rgb(238, 122, 64)'],
    support: [support, 'rgb(128, 92, 245)'],
    loan: [loan, 'rgb(128, 92, 245)'],
  };

  return (
    <Card
      onMouseOver={() => setHover(true)}
      onMouseLeave={() => setHover(false)}
      className={`${classes.root}`}
      style={{
        backgroundImage: `url(${assets[assetProp][0]})`,
        backgroundColor: assets[assetProp][1],
      }}
    >
      <CardHeader
        title={
          <Typography
            className={classes.title}
            gutterBottom
            variant='h5'
            component='h2'
          >
            {title}
          </Typography>
        }
      />
      <CardContent className={classes.content}>
        {hover && (
          <Typography
            className={classes.text}
            variant='body2'
            color='textSecondary'
            component='p'
          >
            {body}
          </Typography>
        )}
      </CardContent>
      {/* {hover && (
        <CardActions className={classes.buttonContainer}>
          <Button className={classes.button} variant='contained'>
            Learn More
          </Button>
        </CardActions>
      )} */}
    </Card>
  );
};

export default FeatureItem;
