import React from 'react';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import AppNavBar from './components/Nav/AppNavBar';
import Home from './components/Home/Home';
import AboutUs from './components/AboutUs/AboutUs';
import PrivacyPolicy from './components/Privacy/PrivacyPolicy';
import DeleteAccount from './components/DeleteAccount/DeleteAccount';

function App() {
  return (
    <Router>
      <Switch>
        <Route exact path='/' render={() => <Home />} />
        <Route path='/about-us' render={() => <AboutUs />} />
        <Route path='/privacy' render={() => <PrivacyPolicy />} />
        <Route path='/delete-account' render={() => <DeleteAccount />} />
      </Switch>
    </Router>
  );
}

export default App;
