import React, { useState } from 'react';
import {
  Box,
  TextField,
  Checkbox,
  Button,
  FormControlLabel,
} from '@material-ui/core';

const DeleteAccount = () => {
  const [phoneNumber, setPhoneNumber] = useState('');
  const [consent, setConsent] = useState(false);

  const handleSubmit = async (event) => {
    event.preventDefault();
    if (!consent) {
      alert('Please confirm your understanding of account deletion.');
      return;
    }

    // Call the backend API to initiate account deletion
    const response = await fetch('/users/initiate-deletion', {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ phoneNumber }),
    });

    alert('A link to delete your account has been sent to your phone number.');
  };

  return (
    <Box>
      <form onSubmit={handleSubmit}>
        <TextField
          label='Phone Number'
          value={phoneNumber}
          onChange={(e) => setPhoneNumber(e.target.value)}
          required
        />
        <Box>
          <FormControlLabel
            control={
              <Checkbox
                checked={consent}
                onChange={(e) => setConsent(e.target.checked)}
                required
              />
            }
            label='I understand that this will delete my account and all associated data.'
          />
        </Box>
        <Button type='submit'>Delete Account</Button>
      </form>
    </Box>
  );
};

export default DeleteAccount;
