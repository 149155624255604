import React from 'react';
import {
  Box,
  Paper,
  Typography,
  IconButton,
  InputBase,
  CardMedia,
} from '@material-ui/core';
import Alert from '@material-ui/lab/Alert';
import { makeStyles } from '@material-ui/core/styles';
import SearchIcon from '@material-ui/icons/Search';
import useWindowDimensions from '../Hooks/useWindowDimensions';
import underline from '../../assets/svg/underline.svg';
import mapDark from '../../assets/images/map/Screenshot 2021-05-17 at 4.29.10 PM.png';

const useStyles = makeStyles((theme) => ({
  root: {
    padding: '2px 4px',
    display: 'flex',
    alignItems: 'center',
    width: 450,
  },
  input: {
    marginLeft: theme.spacing(1),
    flex: 1,
    fontFamily: 'Lexend Deca',
    fontWeight: 600,
    color: '#000',
    fontSize: '14px',
  },
  iconButton: {
    padding: 10,
  },
  header: {
    fontFamily: 'Lexend Deca',
    fontWeight: 'bold',
    fontSize: '58px',
    textAlign: 'center',
    lineHeight: '130%',
    paddingBottom: theme.spacing(7),
    zIndex: 100,
  },
  carousel: {
    height: '85vh',
  },
  underline: {
    fontFamily: 'Lexend Deca',
    fontWeight: 'bold',
    fontSize: '58px',
    textAlign: 'center',
    lineHeight: '130%',
    backgroundImage: `url(${underline})`,
    backgroundSize: '100% 25%',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'bottom',
  },
  mapStyle: {
    height: '80vh',
    display: 'block',
    marginLeft: 'auto',
    marginRight: 'auto',
    width: '40vw',
    borderRadius: '50%',
  },
}));

const FindAgents = () => {
  const classes = useStyles();
  const { height, width } = useWindowDimensions();

  return (
    <Box
      display='flex'
      flexDirection='row'
      mr={10}
      mb={10}
      p={0}
      style={{ width: '100vw', height: height - 100 }}
    >
      <Box
        display='flex'
        flexDirection='column'
        alignItems='center'
        justifyContent='center'
        style={{ width: '45%', height: '100%' }}
        elevation={12}
      >
        <Box display='flex' style={{ width: '75%' }}>
          <Typography className={classes.header}>
            Search for agents
            <span className={classes.underline}> near you</span>
          </Typography>
        </Box>
        <Box display='flex' justifyContent='center'>
          <Paper component='form' variant='outlined' className={classes.root}>
            <IconButton
              type='submit'
              className={classes.iconButton}
              aria-label='search'
            >
              <SearchIcon />
            </IconButton>
            <InputBase
              className={classes.input}
              placeholder='Search for a city, like "Jigjiga"'
              inputProps={{ 'aria-label': 'search google maps' }}
            />
          </Paper>
        </Box>
      </Box>

      <Alert
        variant='outlined'
        severity='warning'
        style={{
          position: 'absolute',
          width: '20%',
          left: 0,
          right: 180,
          marginLeft: 'auto',
          marginRight: 'auto',
          fontFamily: 'Lexend Deca',
          fontWeight: 600,
          fontSize: '14px',
          color: '#eb8d00',
        }}
      >
        This feature will be coming out soon!
      </Alert>

      <Box
        style={{
          width: '55%',
          height: '100%',
        }}
      >
        <CardMedia className={classes.mapStyle} image={mapDark} title='' />
      </Box>
    </Box>
  );
};

export default FindAgents;
