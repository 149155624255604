import React from 'react';
// eslint-disable-next-line import/no-unresolved
import './styles.css';
import { Box, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import FeatureItem from '../FeatureItem/FeatureItem';
import underline from '../../assets/svg/underline.svg';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexWrap: 'wrap',
    '& > *': {
      margin: theme.spacing(1),
      width: theme.spacing(16),
      height: theme.spacing(16),
    },
  },
  headerContainer: {
    paddingBottom: theme.spacing(8),
  },
  header: {
    fontFamily: 'Lexend Deca',
    fontWeight: 'bold',
    fontSize: '42px',
    textAlign: 'center',
    lineHeight: '130%',
  },
  underline: {
    fontFamily: 'Lexend Deca',
    fontWeight: 'bold',
    fontSize: '42px',
    textAlign: 'center',
    lineHeight: '130%',
    backgroundImage: `url(${underline})`,
    backgroundSize: '100% 25%',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'bottom',
  },
}));

const Features = () => {
  const classes = useStyles();

  return (
    <Box
      style={{
        maxWidth: '100vw',
        overflowX: 'hidden',
        paddingLeft: '0px',
        marginTop: 50,
      }}
      id='features'
      display='flex'
      flexDirection='column'
      p={10}
    >
      <Box
        display='flex'
        justifyContent='center'
        className={classes.headerContainer}
      >
        <Typography className={classes.header}>
          Services
          <span className={classes.underline}> Offered</span>
        </Typography>
      </Box>
      <Box
        className='skills'
        display='flex'
        flexDirection='row'
        style={{
          transform: 'translate3d(0px, 0px, 0px)',
          width: '3821px',
          height: '100%',
          margin: '0px',
        }}
        p={1}
        m={1}
      >
        <FeatureItem
          title='Purchase Airtime'
          body='Purchase airtime on Ethio Tel for FREE and instantly.'
          assetProp='airtime'
        />
        <FeatureItem
          title='Send Money Instantly'
          body='Sahay customers can send money to  registered and unregistered users.
          Sending money is free and customers  cannot exceed limit of ETB 8000.'
          assetProp='send'
        />
        <FeatureItem
          title='Pay Business'
          body='Just like you send money to your friends, you can also pay businesses for goods and services at any Merchant that accepts Sahay.'
          assetProp='buyGoods'
        />
        <FeatureItem
          title='Withdraw Cash'
          body='Sahay customers can withdraw at any agent or branch. Cash withdrawal transaction is locked to maximum limit of ETB 8000 per day in accordance with  National Bank of Ethiopia regulations.'
          assetProp='withdraw'
        />
        <FeatureItem
          title='Bill Payment'
          body='Sahay customers can enjoy bill payment services to Ethiopian Airlines, Ethio Telecom Postpaid, Schools, and more for FREE.'
          assetProp='bills'
        />
        <FeatureItem
          title='Bulk Payment'
          body='Bulk payment solutions is for businesses, employers, NGOs, or any entity that have to make payments to a massive number of people and that too on a frequent basis. It allows you to streamline your bulk payments flawlessly, giving you speed, and efficiency on bulk payments like payroll and cash distributions.'
          assetProp='bulk'
        />
        <FeatureItem
          title='Core Banking Integration'
          body='Sahay customers with Rays MFI Core Banking Account can connect and transfer money between their accounts to enjoy flexible and limitless transactions.'
          assetProp='banking'
        />
        <FeatureItem
          title='Customer Support'
          body='Call 8977, +251915164049, +251938833000, or +251926399795 for instant customer support with our world class support team. They are fast, they are smart, and they will help you out any time day or night!'
          assetProp='support'
        />
        <FeatureItem
          title='Digital MicroLoan'
          body='Sahay customers can request for non-secured loan depending on their
          transaction history. The loan is repaid  after 2 weeks or 1 month at max. The  loan is scored using our highly tuned machine learning tool which allocates the accurate and best score.'
          assetProp='loan'
        />
        <FeatureItem
          title='Equb/Hagbad'
          body='Sahay pay is introducing Equb/Hagbad or table banking service to promote saving culture and assist customers to achieve their goals. The product will allow customers to create group, add members and start contributing. Groups can be created for 2 purposes, saving and investment.'
          assetProp='hagbad'
        />
      </Box>
    </Box>
  );
};

export default Features;
