import React from 'react';
import { Box, CardMedia, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import useWindowDimensions from '../Hooks/useWindowDimensions';
import happyMan from '../../assets/images/sahay/sahay1.jpeg';

const useStyles = makeStyles((theme) => ({
  root: {
    margin: theme.spacing(5),
    marginTop: theme.spacing(0),
    // border: '1px solid red',
  },
  text: {
    fontFamily: 'Lexend Deca',
    fontWeight: 600,
    fontSize: '15px',
    letterSpacing: '0.15px',
    textAlign: 'left',
    color: '#6b6b6b',
  },
  header: {
    fontFamily: 'Lexend Deca',
    fontWeight: 'bold',
    fontSize: '38px',
    textAlign: 'left',
    lineHeight: '130%',
    paddingTop: theme.spacing(5),
    marginBottom: '2rem',
    zIndex: 100,
  },
  media: {
    // height: 0,
    // paddingTop: '70.75%',
    width: '27.5rem',
    height: '27.5rem',
    marginLeft: '8rem',
    borderRadius: 10,
  },
}));

const WhoWeAre = () => {
  const classes = useStyles();
  const { height, width } = useWindowDimensions();

  return (
    <Box
      display='flex'
      className={classes.root}
      style={{ width, height: height - 100 }}
    >
      <Box
        display='flex'
        flexDirection='column'
        justifyContent='center'
        ml={5}
        style={{ width: '40%', height: '100%' }}
      >
        <Typography className={classes.header}>Hi. We’re Sahaypay</Typography>
        <Typography
          p={2}
          variant='body1'
          align='center'
          className={classes.text}
          style={{ marginBottom: '2rem' }}
        >
          Sahaypay is the fastest growing Mobile Money Platform in Ethiopia, owned by
          Rays Microfinance Institution S.C. It’s a digital wallet that is
          customer-centric targeted at retail and business customers and has a wide
          range of consolidated products & services from a single platform and
          supports integration to multiple systems.
        </Typography>
        <Typography p={2} variant='body1' align='center' className={classes.text}>
          With a growing population that is increasingly appreciating the value of
          technology and are looking for prompt services at the touch of a button, in
          the comfort of their homes and offices. Sahay Pay is looking to bridge this
          gap and achieve customer and business satisfaction.
        </Typography>
      </Box>
      <Box
        display='flex'
        justifyContent='center'
        alignItems='center'
        mt={2}
        style={{ width: '60%', height: '100%' }}
      >
        <CardMedia className={classes.media} image={happyMan} title='' />
      </Box>
    </Box>
  );
};

export default WhoWeAre;
