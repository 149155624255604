import React, { useState, useEffect } from 'react';
import { CardMedia, Container } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import somaliGuyBg from '../../assets/images/people/no-bg-min.png';
import InfoCard from '../InfoCard/InfoCard';
import InfoCard2 from '../InfoCard/infoCard2';
import InfoCard3 from '../InfoCard/infoCard3';
import InfoCard4 from '../InfoCard/infoCard4';

const useStyles = makeStyles((theme) => ({
  root: {
    height: '100vh',
    width: '100vw',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  dashed: {
    position: 'absolute',
    height: '35vh',
    width: '35vw',
    top: '20%',
  },
  humanBg: {
    position: 'absolute',
    top: '25%',
    left: '52%',
    zIndex: 100,
    height: '95% ',
    width: '33%',
  },
}));

const Overview = () => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <div className={classes.dashed}>
        <svg viewBox='0 0 100 100'>
          <linearGradient x1='0' y1='0' x2='100%' y2='100%' id='gradient'>
            <stop stopColor='#8e6ef6' offset='27%' /> {/* #ffd000 */}
            <stop stopColor='#fff' offset='100%' /> {/* #fff1b1 */}
          </linearGradient>
          <circle
            cx='50'
            cy='50'
            r='45'
            strokeDasharray='2, 1'
            fill='url(#gradient)'
            stroke='black'
            strokeWidth='0'
          />
        </svg>
      </div>
      <div className={classes.dashed}>
        <svg viewBox='0 0 100 100'>
          <circle
            cx='50'
            cy='50'
            r='50'
            strokeDasharray='2, 1'
            fill='transparent'
            stroke='black'
            strokeWidth='0.25'
          />
        </svg>
      </div>
      <InfoCard />
      <InfoCard2 />
      <InfoCard3 />
      <InfoCard4 />
      <CardMedia className={classes.humanBg} image={somaliGuyBg} title='' />
    </div>
  );
};

export default Overview;
