import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Container, Box, Typography, CardMedia } from '@material-ui/core';
import StoreIcon from '../StoreIcon/StoreIcon';
import underline from '../../assets/svg/underline.svg';
import ethiopian from '../../assets/images/partners/et-logo.png';
import ethioTelecom from '../../assets/images/partners/Ethiotelcom_Logo.png';
import hudHud from '../../assets/images/partners/HudHud-logo.png';
import awash from '../../assets/images/partners/awashbank-removebg-high.png';

const useStyles = makeStyles((theme) => ({
  title: {
    fontFamily: 'Lexend Deca',
    fontWeight: 'bold',
    fontSize: '1.8em', // 64px
    textAlign: 'center',
    lineHeight: '130%',
    color: '#FFF',
    '@media (min-width:1480px)': {
      fontSize: '2.3em',
    },
  },
  text: {
    fontFamily: 'Lexend Deca',
    fontWeight: 600,
    textAlign: 'center',
    color: '#FFF',
    fontSize: '1.05rem',
    marginTop: theme.spacing(1),
    marginRight: theme.spacing(10),
    marginLeft: theme.spacing(10),
    '@media (min-width:1480px)': {
      fontSize: '1.55em',
    },
  },
  textSmall: {
    fontFamily: 'Lexend Deca',
    fontWeight: 600,
    textAlign: 'left',
    color: '#FFF',
    fontSize: '1vw',
    display: 'flex',
    alignItems: 'center',
    paddingRight: '2%',
    paddingTop: 15,
  },
  image1: {
    paddingLeft: theme.spacing(0.5),
    height: '3.2rem',
    width: '8.7rem',
  },
  image2: {
    marginTop: theme.spacing(2),
    height: '2.2rem',
    width: '8rem',
  },
  image3: {
    zIndex: 20,
    marginTop: theme.spacing(3),
    marginLeft: theme.spacing(1),
    height: '2.2rem',
    width: '7.5rem',
  },
  image4: {
    zIndex: 20,
    marginTop: theme.spacing(3),
    marginLeft: theme.spacing(1),
    height: '5rem',
    width: '6.4rem',
  },
  underline: {
    fontFamily: 'Lexend Deca',
    fontWeight: 'bold',
    fontSize: '1.8em',
    textAlign: 'center',
    lineHeight: '130%',
    backgroundImage: `url(${underline})`,
    backgroundSize: '100% 25%',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'bottom',
    color: '#FFF',
    '@media (min-width:1480px)': {
      fontSize: '2.5em',
    },
  },
}));

const Description = ({ isSection1, text }) => {
  const classes = useStyles();

  return (
    <Box
      flexDirection='column'
      ml={5}
      style={{
        height: '100%',
        width: '100%',
      }}
    >
      <Box flexGrow={1} style={{ height: '10%' }} />
      <Box
        justifyContent='center'
        style={{
          height: '45%',
          width: '100%',
          marginTop: '5%',
          // marginBottom: '5%',
        }}
      >
        {isSection1 ? (
          <Typography variant='h3' gutterBottom className={classes.title}>
            <span className={classes.title}>The </span>
            <span className={classes.underline}>simpler, </span>
            <span className={classes.title}>safer way to pay.</span>
          </Typography>
        ) : (
          <Typography variant='h3' gutterBottom className={classes.title}>
            <span className={classes.title}>Privacy and </span>
            <span className={classes.underline}>security </span>
            <span className={classes.title}>built into every payment.</span>
          </Typography>
        )}
        <Typography variant='subtitle1' gutterBottom className={classes.text}>
          {text}
        </Typography>
      </Box>
      {isSection1 && (
        <Box
          style={{
            height: '15%',
            width: '100%',
          }}
        >
          <StoreIcon />
        </Box>
      )}
      {isSection1 && (
        <Box
          style={{
            height: '15%',
            width: '45%',
            marginTop: '5%',
            padding: '5%',
            position: 'absolute',
            left: 0,
            bottom: '-10%',
          }}
        >
          <Box
            display='flex'
            flexDirection='row'
            alignItems='center'
            style={{ height: '100%', width: '100%' }}
          >
            <Typography className={classes.textSmall}>Our Partners</Typography>
            <CardMedia className={classes.image1} image={ethiopian} title='' />
            <CardMedia className={classes.image2} image={ethioTelecom} title='' />
            <CardMedia className={classes.image3} image={hudHud} title='' />
            <CardMedia className={classes.image4} image={awash} title='' />
          </Box>
        </Box>
      )}
    </Box>
  );
};

export default Description;
