import React from 'react';
import { Box, CardMedia, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import useWindowDimensions from '../Hooks/useWindowDimensions';
import office from '../../assets/images/sahay/IMG_0269.JPG';

const useStyles = makeStyles((theme) => ({
  root: {
    marginBottom: theme.spacing(5),
    backgroundColor: '#e5f4fe',
  },
  text: {
    fontFamily: 'Lexend Deca',
    fontWeight: 600,
    fontSize: '15px',
    letterSpacing: '0.15px',
    textAlign: 'left',
    color: '#6b6b6b',
  },
  header: {
    fontFamily: 'Lexend Deca',
    fontWeight: 'bold',
    fontSize: '38px',
    textAlign: 'left',
    lineHeight: '130%',
    marginBottom: '2rem',
    zIndex: 100,
  },
  media: {
    // height: 0,
    // paddingTop: '70.75%',
    width: '27.5rem',
    height: '27.5rem',
    marginRight: '8rem',
    borderRadius: 10,
  },
}));

const ParentCompany = () => {
  const classes = useStyles();
  const { height, width } = useWindowDimensions();

  return (
    <Box
      display='flex'
      justifyContent='center'
      className={classes.root}
      style={{ width, height: height - 100 }}
    >
      <Box
        display='flex'
        justifyContent='center'
        alignItems='center'
        p={5}
        // pt={2}
        style={{ width: '50%', height: '100%' }}
      >
        <CardMedia className={classes.media} image={office} title='' />
      </Box>
      <Box
        display='flex'
        flexDirection='column'
        justifyContent='center'
        style={{
          width: '50%',
          paddingRight: 10,
          height: '100%',
        }}
      >
        <Typography className={classes.header}>
          Part of the Rays MFI family
        </Typography>
        <Typography
          p={2}
          variant='body1'
          align='center'
          className={classes.text}
          style={{ marginBottom: '2rem' }}
        >
          Established on June 2014, Rays Microfinance Institution S.C. was an
          inception of few individuals that had a deep-rooted desire to contribute to
          and play a role towards mitigating the vulnerability and poverty of
          low-income people country in general and in Somali Region in particular.
          They had a well-developed experience in the areas of business and
          developmental endeavours, and have good understanding about the
          contribution and the role of access to finance in mitigate the
          vulnerability and poverty of low-income people. Thus, they shared their
          idea to like-minded fellows that gained remarkable acceptance, which in
          turn led to the establishment of Rays Microfinance Institution S.C.
        </Typography>
      </Box>
    </Box>
  );
};

export default ParentCompany;
