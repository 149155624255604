import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {
  Paper,
  Typography,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
} from '@material-ui/core';
import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '16%', // 190
    height: '14.5%', // 100
    zIndex: 200,
    position: 'absolute',
    right: '40%',
    bottom: '65%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
    borderTopRightRadius: 15,
    borderTopLeftRadius: 15,
    borderBottomRightRadius: 0,
    borderBottomLeftRadius: 15,
  },
  text: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
    flexGrow: 1,
  },
  title: {
    fontFamily: 'Lexend Deca',
    fontWeight: 600,
    color: '#6b6b6b',
    fontSize: '0.9vw',
  },
}));

const InfoCard2 = () => {
  const classes = useStyles();

  return (
    <Paper elevation={2} className={classes.root}>
      <List component='ol'>
        <ListItem>
          <FiberManualRecordIcon
            style={{ fontSize: '0.7vw', color: '#06f', paddingRight: 5 }}
          />
          <Typography className={classes.title}>Largest agent network</Typography>
        </ListItem>
        <ListItem>
          <FiberManualRecordIcon
            style={{ fontSize: '0.7vw', color: '#06f', paddingRight: 5 }}
          />
          <Typography className={classes.title}>Largest merchant network</Typography>
        </ListItem>
      </List>
    </Paper>
  );
};

export default InfoCard2;
