import React from 'react';
import { Box, CardMedia, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import useWindowDimensions from '../Hooks/useWindowDimensions';
import teamMeeting from '../../assets/images/sahay/team-picture.JPG';

const useStyles = makeStyles((theme) => ({
  root: {
    // marginBottom: theme.spacing(5),
    // backgroundColor: '#e5f4fe',
    // border: '1px solid red',
    margin: theme.spacing(5),
  },
  text: {
    fontFamily: 'Lexend Deca',
    fontWeight: 600,
    fontSize: '15px',
    letterSpacing: '0.15px',
    textAlign: 'left',
    color: '#6b6b6b',
  },
  header: {
    fontFamily: 'Lexend Deca',
    fontWeight: 'bold',
    fontSize: '38px',
    textAlign: 'Left',
    lineHeight: '130%',
    paddingTop: theme.spacing(5),
    marginBottom: '2rem',
    zIndex: 100,
  },
  media: {
    width: '37.5rem',
    height: '27.5rem',
    marginLeft: '8rem',
    borderRadius: 10,
  },
}));

const Remittance = () => {
  const classes = useStyles();
  const { height, width } = useWindowDimensions();

  return (
    <Box
      display='flex'
      justifyContent='center'
      className={classes.root}
      style={{ width: width - 10, height: height - 100, paddingRight: 20 }}
    >
      <Box
        display='flex'
        flexDirection='column'
        // justifyContent='center'
        // alignItems='center'
        m={10}
        ml={5}
        mr={0}
        mt={20}
        style={{ width: '50%', height: '100%' }}
      >
        <Typography className={classes.header}>International Remittance.</Typography>
        <Typography
          p={2}
          variant='body1'
          align='center'
          className={classes.text}
          style={{ marginBottom: '2rem' }}
        >
          Sahay pay has partnered with Awash Bank and major remittance companies to
          forward remitted cash to Sahay Pay’s Wallet directly. Sahay Pay customers
          will enjoy this service as a good number of population receive funds from
          abroad.
        </Typography>
      </Box>
      <Box
        display='flex'
        justifyContent='center'
        alignItems='center'
        style={{ width: '65%', height: '100%' }}
      >
        <CardMedia className={classes.media} image={teamMeeting} title='' />
      </Box>
    </Box>
  );
};

export default Remittance;
