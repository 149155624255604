import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Paper, Typography, CardHeader, Avatar } from '@material-ui/core';
import { red, pink } from '@material-ui/core/colors';
import LockIcon from '@material-ui/icons/Lock';
import AccountBalanceIcon from '@material-ui/icons/AccountBalance';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '13.5%', // 185
    height: '19%', // 120
    zIndex: 200,
    position: 'absolute',
    right: '10%',
    bottom: '68%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
    borderTopRightRadius: 15,
    borderTopLeftRadius: 15,
    borderBottomRightRadius: 15,
    borderBottomLeftRadius: 0,
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1),
  },
  avatar: {
    color: '#fff',
    backgroundColor: '#06f',
    flexGrow: 1,
    '@media (min-width:1480px)': {
      width: theme.spacing(6.5),
      height: theme.spacing(6.5),
    },
  },
  text: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
    flexGrow: 1,
    paddingBottom: theme.spacing(1.5),
  },
  title: {
    fontFamily: 'Lexend Deca',
    fontWeight: 600,
    color: '#6b6b6b',
    fontSize: '0.9vw',
    flexGrow: 1,
    textAlign: 'center',
  },
  number: {
    fontFamily: 'Lexend Deca',
    fontWeight: 600,
    fontSize: 16,
    color: '#6b6b6b',
  },
}));

const InfoCard3 = () => {
  const classes = useStyles();

  return (
    <Paper elevation={2} className={classes.root}>
      <CardHeader
        style={{ padding: 15 }}
        avatar={
          <Avatar className={classes.avatar}>
            <AccountBalanceIcon style={{ fontSize: '1.5vw' }} />
          </Avatar>
        }
      />
      <div className={classes.text}>
        <Typography className={classes.title} color='textSecondary' gutterBottom>
          Regulated by the National bank of Ethiopia
        </Typography>
      </div>
    </Paper>
  );
};

export default InfoCard3;
