import React from 'react';
import {
  Box,
  AppBar,
  Typography,
  CardMedia,
  Container,
  Toolbar,
  IconButton,
  Link,
} from '@material-ui/core';
import { Link as RouterLink } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import FacebookIcon from '@material-ui/icons/Facebook';
import StoreIcon from '../StoreIcon/StoreIcon';
import logo2 from '../../assets/images/Sahay_Logo-removebg-preview.png';

const useStyles = makeStyles((theme) => ({
  footer: {
    backgroundColor: theme.palette.background.paper,
    padding: theme.spacing(6),
  },
  text: {
    fontFamily: 'Lexend Deca',
    fontWeight: 600,
    color: '#6b6b6b',
    fontSize: '1em',
    paddingBottom: theme.spacing(1),
  },
  title: {
    fontFamily: 'Lexend Deca',
    fontWeight: 600,
    color: '#000',
    fontSize: '1em',
    letterSpacing: '0.15px',
    paddingBottom: theme.spacing(2),
  },
  image: {
    marginLeft: theme.spacing(5),
    marginBottom: theme.spacing(2),
    height: 50,
    width: 130,
    borderRadius: 5,
  },
  spacingLogo: {
    marginLeft: theme.spacing(5),
    fontFamily: 'Lexend Deca',
    fontWeight: 600,
    color: '#6b6b6b',
    fontSize: '14px',
  },
}));

const Footer = () => {
  const classes = useStyles();
  const url = window.location.href;
  const path = url.split('/');

  const navigateToServices = () => {
    if (path[path.length - 1] === 'about-us') {
      return '/#features';
    }

    return '#features';
  };

  const toServices = navigateToServices();

  return (
    <footer className={classes.footer}>
      <Box display='flex' flexDirection='row' justifyContent='space-around'>
        <Box display='flex' flexDirection='column' style={{ width: '35%' }}>
          <CardMedia className={classes.image} image={logo2} title='company log' />
        </Box>
        <Box display='flex' flexDirection='column' style={{ width: '35%' }}>
          <Typography className={classes.title}>CONTACT</Typography>
          <Box>
            <Typography className={classes.text}>
              Kirkos Sub-city, Around Wollo-Sefer
            </Typography>
            <Typography className={classes.text}>info@raysmfi.com</Typography>
            <Typography className={classes.text}>+251 11 470 1834</Typography>
            <Typography className={classes.text}>
              7 Days a week from 8:30 am to 5:30 pm
            </Typography>
          </Box>
        </Box>
        <Box
          display='flex'
          flexDirection='column'
          style={{ minHeight: 170, width: '25%' }}
        >
          <Typography className={classes.title}>COMPANY</Typography>
          <Box>
            <Link underline='none' component={RouterLink} to='/about-us'>
              <Typography className={classes.text}>About us</Typography>
            </Link>
            <Link underline='none' href={toServices} component='a'>
              <Typography className={classes.text}>Services</Typography>
            </Link>
          </Box>
        </Box>
        <Box
          display='flex'
          flexDirection='column'
          style={{ minHeight: 170, width: '25%' }}
        >
          <Typography className={classes.title}>PRODUCTS</Typography>
          <Typography className={classes.text}>Customer</Typography>
          <Typography className={classes.text}>Agent</Typography>
          <Typography className={classes.text}>Business</Typography>
        </Box>
      </Box>
    </footer>
  );
};

export default Footer;
