import React from 'react';
import { CardMedia, Box, Link } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import iOSIcon from '../../assets/svg/badge_appstore-lrg.svg';
import androidIcon from '../../assets/images/google-play-badge.png';

const useStyles = makeStyles((theme) => ({
  root: {
    height: '100%',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    '@media (max-width:1400px)': {
      marginTop: theme.spacing(5),
    },
  },
  iosContainer: {
    marginRight: 25,
    width: '40%',
  },
  iosIconStyle: {
    height: '67%',
    '@media (min-width:1480px)': {
      height: '57%',
    },
    borderRadius: 7,
  },
  androidContainer: {
    marginRight: 25,
    width: '40%',
  },
  androidIconStyle: {
    height: '67%',
    '@media (min-width:1480px)': {
      height: '57%',
    },
    borderRadius: 7,
  },
}));

const StoreIcon = () => {
  const classes = useStyles();

  return (
    <Box wrap='wrap' spacing={1} className={classes.root}>
      <Box className={classes.iosContainer}>
        <Link
          underline='none'
          href='https://apps.apple.com/us/app/sahay/id1537589273'
        >
          <CardMedia className={classes.iosIconStyle} image={iOSIcon} title='' />
        </Link>
      </Box>
      <Box className={classes.androidContainer}>
        <Link
          underline='none'
          href='https://play.google.com/store/apps/details?id=com.sahay.sahaymoney&gl=ES'
        >
          <CardMedia
            className={classes.androidIconStyle}
            image={androidIcon}
            title=''
          />
        </Link>
      </Box>
    </Box>
  );
};

export default StoreIcon;
