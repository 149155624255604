import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Paper, Typography, Avatar, CardHeader } from '@material-ui/core';
import StarIcon from '@material-ui/icons/Star';
import SentimentSatisfiedAltIcon from '@material-ui/icons/SentimentSatisfiedAlt';
import { green } from '@material-ui/core/colors';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '11%', // 140
    height: '21%', // 140
    zIndex: 200,
    position: 'absolute',
    right: '40%',
    bottom: '25%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    alignSelf: 'center',
    flexDirection: 'column',
    borderTopRightRadius: 0,
    borderTopLeftRadius: 15,
    borderBottomRightRadius: 15,
    borderBottomLeftRadius: 15,
  },
  text: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
    flexGrow: 1,
    paddingTop: 10,
  },
  title: {
    fontFamily: 'Lexend Deca',
    fontWeight: 600,
    color: '#6b6b6b',
    fontSize: '0.9vw',
  },
  stars: {
    display: 'flex',
    flexDirection: 'row',
    // padding: 10,
    // paddingTop: 10,
  },
  emoji: {
    fontSize: 30,
    paddingTop: 10,
  },
  avatar: {
    color: '#fff',
    backgroundColor: '#06f',
    flexGrow: 1,
    '@media (min-width:1480px)': {
      width: theme.spacing(6.5),
      height: theme.spacing(6.5),
    },
  },
}));

const InfoCard4 = () => {
  const classes = useStyles();

  return (
    <Paper elevation={2} className={classes.root}>
      <CardHeader
        avatar={
          <Avatar className={classes.avatar}>
            <SentimentSatisfiedAltIcon style={{ fontSize: '1.6vw' }} />
          </Avatar>
        }
      />
      <div className={classes.stars}>
        <StarIcon style={{ color: '#fc0' }} />
        <StarIcon style={{ color: '#fc0' }} />
        <StarIcon style={{ color: '#fc0' }} />
        <StarIcon style={{ color: '#fc0' }} />
        <StarIcon style={{ color: '#fc0' }} />
      </div>
      <div className={classes.text}>
        <Typography className={classes.title} color='textSecondary' gutterBottom>
          Customer satisfaction
        </Typography>
      </div>
    </Paper>
  );
};

export default InfoCard4;
