import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Divider, Box, Container, Typography } from '@material-ui/core';
import Carousel from 'react-material-ui-carousel';
import ArrowBackRoundedIcon from '@material-ui/icons/ArrowBackRounded';
import ArrowForwardRoundedIcon from '@material-ui/icons/ArrowForwardRounded';
import useWindowDimensions from '../Hooks/useWindowDimensions';
import Description from '../Description/Description';
import Overview from '../Overview/Overview';
import Features from '../Features/Features';
import Footer from '../Footer/Footer';
import Reviews from '../Reviews/Reviews';
import FindAgents from '../FindAgents/FindAgents';
import AppNavBar from '../Nav/AppNavBar';
import DescriptionSection1 from '../DescriptionSection1/DescriptionSection1';
import DescriptionSection2 from '../DescriptionSection2/DescriptionSection2';

const useStyles = makeStyles((theme) => ({
  divider: {
    background: 'grey',
    width: '90%',
  },
  carousel: {
    height: '100vh',
    border: '1px solid red',
  },
}));

const Home = () => {
  const { height, width } = useWindowDimensions();
  // eslint-disable-next-line no-console
  console.log('height, width', height, width);
  const classes = useStyles();

  return (
    <Box
      style={{
        width: '100%',
        height: '100%',
      }}
    >
      <AppNavBar />
      {/* --- CAROUSEL START --- */}
      <DescriptionSection1 />
      {/* --- CAROUSEL END --- */}
      <Box
        style={{
          marginLeft: -20,
          marginBottom: 100,
          width: '100vw',
          height: '100vh',
        }}
      >
        <Features />
      </Box>
      <Box m={0} p={0}>
        <FindAgents />
      </Box>
      <Box>
        <Reviews />
      </Box>
      <Box style={{ minHeight: 270, width: '100%' }}>
        <Box display='flex' justifyContent='center'>
          <Divider variant='middle' className={classes.divider} />
        </Box>
        <Footer />
      </Box>
    </Box>
  );
};

export default Home;
