import React from 'react';
import {
  Box,
  CardMedia,
  Typography,
  Button,
  MuiThemeProvider,
  createMuiTheme,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { pink, blue } from '@material-ui/core/colors';
import useWindowDimensions from '../Hooks/useWindowDimensions';
import support from '../../assets/images/3d-icons/support-white.png';

const useStyles = makeStyles((theme) => ({
  root: {
    marginBottom: theme.spacing(10),
    maxWidth: '95%',
    height: '70vh',
    width: '95vw',
    backgroundColor: '#8e6ef6',
    borderRadius: 12,
  },
  text: {
    fontFamily: 'Lexend Deca',
    fontWeight: 500,
    fontSize: '1.1em',
    letterSpacing: '0.15px',
    textAlign: 'left',
    color: '#FFF',
  },
  header: {
    fontFamily: 'Lexend Deca',
    fontWeight: 'bold',
    fontSize: '2em',
    textAlign: 'left',
    lineHeight: '130%',
    marginBottom: '2rem',
    zIndex: 100,
    color: '#FFF',
  },
  media: {
    width: '18rem',
    height: '14rem',
    marginRight: '8rem',
    marginBottom: '4rem',
  },
}));

const blueTheme = createMuiTheme({
  palette: {
    primary: {
      main: '#000',
    },
    secondary: pink,
  },
});

const GetInTouch = () => {
  const classes = useStyles();
  const { height, width } = useWindowDimensions();

  const OpenChat = () => {
    FB.CustomerChat.showDialog();
  };

  return (
    <Box display='flex' className={classes.root}>
      <Box
        display='flex'
        justifyContent='center'
        alignItems='center'
        p={5}
        style={{ width: '50%', height: '100%' }}
      >
        <CardMedia className={classes.media} image={support} title='' />
      </Box>
      <Box
        display='flex'
        flexDirection='column'
        justifyContent='center'
        style={{
          width: '50%',
          height: '100%',
        }}
      >
        <Typography className={classes.header}>Want to get in touch?</Typography>
        <Typography
          p={2}
          mb={15}
          variant='body1'
          align='center'
          className={classes.text}
          style={{ marginBottom: '2rem' }}
        >
          Meet our world class support team. They are fast, they are smart, and they
          will help you out any time day or night.
        </Typography>
        <Box display='flex' justifyContent='flex-start'>
          <MuiThemeProvider theme={blueTheme}>
            <Button
              size='large'
              variant='outlined'
              color='primary'
              style={{
                fontFamily: 'Lexend Deca',
                fontWeight: 600,
                marginTop: '1rem',
              }}
              onClick={() => OpenChat()}
            >
              Chat with us
            </Button>
          </MuiThemeProvider>
        </Box>
      </Box>
    </Box>
  );
};

export default GetInTouch;
