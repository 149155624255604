import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {
  IconButton,
  AppBar,
  Toolbar,
  Typography,
  Button,
  CardMedia,
  Link,
} from '@material-ui/core';
import { Link as RouterLink, useHistory } from 'react-router-dom';
import FacebookIcon from '@material-ui/icons/Facebook';
import TwitterIcon from '@material-ui/icons/Twitter';
import InstagramIcon from '@material-ui/icons/Instagram';
import LinkedInIcon from '@material-ui/icons/LinkedIn';
import logo2 from '../../assets/images/Sahay_Logo-removebg-preview.png';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    marginBottom: theme.spacing(5),
  },
  title: {
    flexGrow: 1,
  },
  bar: {
    backgroundColor: '#fff',
    color: '#000',
  },
  image: {
    marginTop: theme.spacing(0.5),
    marginLeft: theme.spacing(2),
    height: 50,
    width: 130,
    borderRadius: 5,
  },
  menu: {
    paddingRight: theme.spacing(5),
    fontFamily: 'Lexend Deca',
    fontWeight: 600,
    cursor: 'pointer',
    fontSize: '0.95vw',
    borderRadius: '4px',
    color: '#6b6b6b',
  },
  sectionDesktop: {
    display: 'none',
    [theme.breakpoints.up('md')]: {
      display: 'flex',
      alignSelf: 'center',
      marginRight: theme.spacing(5),
    },
  },
  toolbar: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  logo: {
    paddingLeft: theme.spacing(3),
    fontFamily: 'Lexend Deca',
    fontWeight: 600,
    fontSize: 22,
    color: '#ffa500',
    letterSpacing: 1,
  },
}));

export default function ButtonAppBar() {
  const classes = useStyles();
  const url = window.location.href;
  const path = url.split('/');

  const navigateToServices = () => {
    if (path[path.length - 1] === 'about-us') {
      return '/#features';
    }

    return '#features';
  };

  const navigateToReviews = () => {
    if (path[path.length - 1] === 'about-us') {
      return '/#reviews';
    }

    return '#reviews';
  };

  const toServices = navigateToServices();
  const toReviews = navigateToReviews();

  return (
    <div id='home' className={classes.root}>
      <AppBar elevation={0} className={classes.bar} position='static'>
        <Toolbar className={classes.toolbar}>
          <CardMedia className={classes.image} title='' image={logo2} />

          <div className={classes.sectionDesktop}>
            <Link underline='none' component={RouterLink} to='/'>
              <Typography color='primary' className={classes.menu}>
                HOME
              </Typography>
            </Link>
            <Link underline='none' component={RouterLink} to='/about-us'>
              <Typography color='primary' className={classes.menu}>
                ABOUT US
              </Typography>
            </Link>
            <Link underline='none' href={toServices} component='a'>
              <Typography color='primary' className={classes.menu}>
                SERVICES
              </Typography>
            </Link>
            <Link underline='none' href={toReviews} component='a'>
              <Typography color='primary' className={classes.menu}>
                REVIEWS
              </Typography>
            </Link>
          </div>
          <div>
            <Link
              underline='none'
              href='https://www.facebook.com/Sahay-Mobile-Banking-112850757017747'
              component='a'
            >
              <FacebookIcon
                style={{ fontSize: '1.8vw', color: '#6b6b6b', paddingRight: 18 }}
              />
            </Link>
          </div>
        </Toolbar>
      </AppBar>
    </div>
  );
}
