import React from 'react';
import {
  Box,
  Avatar,
  Paper,
  Typography,
  CardMedia,
  Button,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import Carousel from 'react-material-ui-carousel';
import ArrowBackRoundedIcon from '@material-ui/icons/ArrowBackRounded';
import ArrowForwardRoundedIcon from '@material-ui/icons/ArrowForwardRounded';
import ReviewItems from '../ReviewItems/ReviewItems';
import underline from '../../assets/svg/underline.svg';
import person1 from '../../assets/images/people/HD-removebg-min.png';
import person2 from '../../assets/images/people/Customer-removebg-preview.png';

const useStyles = makeStyles((theme) => ({
  header: {
    fontFamily: 'Lexend Deca',
    fontWeight: 'bold',
    fontSize: '42px',
    textAlign: 'center',
    lineHeight: '130%',
    paddingBottom: theme.spacing(17),
    zIndex: 100,
  },
  underline: {
    fontFamily: 'Lexend Deca',
    fontWeight: 'bold',
    fontSize: '42px',
    textAlign: 'center',
    lineHeight: '130%',
    backgroundImage: `url(${underline})`,
    backgroundSize: '100% 25%',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'bottom',
  },
  carousel: {
    height: '85vh',
  },
}));

const Reviews = () => {
  const classes = useStyles();

  return (
    <Box display='flex' flexDirection='column' mt={12} mb={15} id='reviews'>
      <Box display='flex' justifyContent='center'>
        <Typography className={classes.header}>
          Our customers say
          <span className={classes.underline}> it best</span>
        </Typography>
      </Box>
      <Box
        id='reviews'
        ml={2}
        mr={2}
        display='flex'
        alignSelf='center'
        justifyContent='center'
        alignItems='center'
        style={{
          maxWidth: '95%',
          height: '73vh',
          display: 'flex',
          backgroundColor: '#ffd000',
          // borderRadius: '95% 4% 97% 5%/4% 94% 3% 95%',
          borderRadius: 12,
        }}
      >
        <Carousel
          animation='slide'
          indicators={false}
          className={classes.carousel}
          NextIcon={<ArrowForwardRoundedIcon />}
          PrevIcon={<ArrowBackRoundedIcon />}
        >
          <ReviewItems
            name='Abdibasid'
            review='I pay my bills with it and you can do everything with it without having to travel, it is simple, easy and efficient.'
            occupation='Business man'
            image={person1}
            imageStyle={1}
          />
          <ReviewItems
            name='Abdikayd Buraale'
            review='Simple, convenient, mostly free, and always upfront. I am really very satisfied with their service, I highly recommend it.'
            occupation='Business man'
            image={person2}
            imageStyle={2}
          />
        </Carousel>
      </Box>
    </Box>
  );
};

export default Reviews;
