import React from 'react';

const rawHTML = `
<html>
    <head>
        <meta charset='utf-8'>
        <meta name='viewport' content='width=device-width'>
        <title>Privacy Policy</title>
        <style> body { font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif; padding:1em; } </style>
    </head>
    <body>
        <strong>Privacy Policy</strong>
        <p>
            This privacy notice for Rays MFI ("Company," "we," "us," or "our"), describes how and why we obtain, use, store, manage and dispose of certain information when you use our services ("Services"), such as when you download and use our mobile application (Sahay), or any other application of ours that links to this privacy notice.
        </p>
        <p>
            Have questions or concerns? Reading this privacy notice will help you understand your privacy rights and choices. If you still have any questions or concerns, please contact us at info@raysmfi.com or call 8977.
        </p>
        <p><strong>Collection of Information</strong></p>
        <p>
            In Short: We collect personal information that you provide to us.
        </p>
        <p>
            We collect personal information that you voluntarily provide to us when you register on the Services, express an interest in obtaining information about us or our products and Services, when you participate in activities on the Services, or otherwise when you contact us.
        </p>
        <p>
            Personal Information Provided by You. The personal information that we collect depends on the context of your interactions with us and the Services, the choices you make, and the products and features you use. This includes information about yourself, such as your full legal name, and telephone number, as well as other information and/or documentation that will allow us to verify your identity.
        </p>
        <p>
            All personal information that you provide to us must be true, complete, and accurate, and you must notify us of any changes to such personal information.
        </p>
        <p><strong>Camera Usage</strong></p>
        <p>
            Our app requires access to your device's camera for two specific purposes:
        </p>
        <ul>
            <li>Know Your Customer (KYC) Process: We use the camera to capture a selfie as part of our identity verification process. This helps us comply with regulatory requirements and ensure the security of our users.</li>
            <li>QR Code Scanning: The camera is used to scan QR codes for facilitating transactions, such as payments to merchants or withdrawals from agents.</li>
        </ul>
        <p>
            We do not use the camera for any other purpose, and we do not store or share the images captured for QR code scanning. Selfie images captured for KYC purposes are securely transmitted to our servers for verification and are handled in accordance with our data retention policies and applicable regulations.
        </p>
        <p>
            You can control the app's access to your camera through your device's settings. While camera access is important for full functionality, users can still use core features of the app if they choose not to grant camera permissions.
        </p>
        <!---->
        <p><strong>How do we keep your information safe?</strong></p>
        <p>
            In Short: We aim to protect your personal information through a system of organizational and technical security measures. All such information is stored on servers with state-of-the-art data protection technology.
        </p>
        <p>
            No method of transmission over the internet, or method of electronic storage, is 100% secure, however. Although Rays MFI uses strong methods to protect the data that you provide to us, and we regularly test our information protection practices and technology, Rays MFI cannot absolutely guarantee its security. We will, however, notify you of any breach or other compromise that affects your data, promptly and in compliance with applicable law.
        </p>
        <p><strong>Your Right to Access and Correct Your Personal Information</strong></p>
        <p>
            In Short: You may review, change, or terminate your account at any time.
        </p>
        <p>
            If you have questions or comments about your privacy rights, you may email us at info@raysmfi.com or call 8977.
        </p>
        <p><strong>Privacy policy changes</strong></p>
        <p>
            We may update this privacy notice from time to time. The updated version will be indicated by an updated "Revised" date and the updated version will be effective as soon as it is accessible. If we make material changes to this privacy notice, we may notify you either by prominently posting a notice of such changes or by directly sending you a notification. We encourage you to review this privacy notice frequently to be informed of how we are protecting your information.
        </p>
        <p><strong>Contact Us</strong></p>
        <p>
            If you have any questions or suggestions about our Privacy Policy, do
            not hesitate to contact us at info@raysmfi.com or call 8977.
        </p>
    </body>
</html>
`;

const PrivacyPolicy = () => <div dangerouslySetInnerHTML={{ __html: rawHTML }} />;

export default PrivacyPolicy;
