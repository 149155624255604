import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {
  Paper,
  useMediaQuery,
  Typography,
  CardHeader,
  Avatar,
} from '@material-ui/core';
import { red, pink } from '@material-ui/core/colors';
import PeopleAltRoundedIcon from '@material-ui/icons/PeopleAltRounded';
import CountUp from 'react-countup';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '14.5%', // 205
    height: '19%', // 135
    zIndex: 200,
    position: 'absolute',
    right: '3%',
    bottom: '30%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
    borderTopRightRadius: 15,
    borderTopLeftRadius: 15,
    borderBottomRightRadius: 15,
    borderBottomLeftRadius: 0,
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1),
  },
  avatar: {
    color: '#fff',
    backgroundColor: '#06f',
    flexGrow: 1,
    '@media (min-width:1480px)': {
      width: theme.spacing(6.5),
      height: theme.spacing(6.5),
    },
  },
  text: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
    flexGrow: 1,
  },
  title: {
    fontFamily: 'Lexend Deca',
    fontWeight: 600,
    color: '#6b6b6b',
    fontSize: '0.9vw', // 13
    textAlign: 'center',
  },
  number: {
    fontFamily: 'Lexend Deca',
    fontWeight: 600,
    // fontSize: 16,
    fontSize: '1.1vw',
    color: '#6b6b6b',
  },
}));

const InfoCard = () => {
  const classes = useStyles();
  const matches = useMediaQuery('(min-width:600px)');

  return (
    <Paper elevation={2} className={classes.root}>
      <CardHeader
        avatar={
          <Avatar className={classes.avatar}>
            <PeopleAltRoundedIcon style={{ fontSize: '1.6vw' }} />
          </Avatar>
        }
      />
      <div className={classes.text}>
        <Typography className={classes.title} color='textSecondary' gutterBottom>
          Fast Growing Customers
        </Typography>
        <Typography className={classes.number} color='textSecondary' gutterBottom>
          <CountUp end={690570} duration={2.75} separator=',' />
        </Typography>
      </div>
    </Paper>
  );
};

export default InfoCard;
