import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Divider, Box, Container, Typography } from '@material-ui/core';
import useWindowDimensions from '../Hooks/useWindowDimensions';
import AppNavBar from '../Nav/AppNavBar';
import WhoWeAre from '../WhoWeAre/WhoWeAre';
import ParentCompany from '../WhoWeAre/ParentCompany';
import Mission from '../WhoWeAre/Mission';
import Footer from '../Footer/Footer';
import GetInTouch from '../GetInTouch/GetInTouch';
import Interoperability from '../WhoWeAre/Interoperability';
import Remittance from '../WhoWeAre/Remittance';

const useStyles = makeStyles((theme) => ({
  divider: {
    background: 'grey',
    width: '90%',
  },
}));

const AboutUs = () => {
  const { height, width } = useWindowDimensions();
  const classes = useStyles();

  return (
    <Box
      display='flex'
      flexDirection='column'
      style={{
        width: '100%',
        height: '100%',
      }}
    >
      <AppNavBar />
      <Box display='flex'>
        <WhoWeAre />
      </Box>
      <Box display='flex'>
        <ParentCompany />
      </Box>
      <Box display='flex'>
        <Mission />
      </Box>
      <Box display='flex'>
        <Interoperability />
      </Box>
      <Box display='flex'>
        <Remittance />
      </Box>
      <Box display='flex' justifyContent='center'>
        <GetInTouch />
      </Box>
      <Box>
        <Box display='flex' justifyContent='center'>
          <Divider variant='middle' className={classes.divider} />
        </Box>
        <Footer />
      </Box>
    </Box>
  );
};

export default AboutUs;
